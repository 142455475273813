import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function LoadingSkeleton() {
  return (
    <Grid
      container
      className='flex-row border justify-center border-red-300 mt-3'
    >
      <Grid>
        <Skeleton
          variant='circular'
          animation='wave'
          className='m-auto'
          width={120}
          height={120}
        />
      </Grid>
      <Grid lg={8}>
        <Skeleton
          variant='text'
          animation='wave'
          className='mx-auto rounded-lg'
          width={700}
          height={40}
        />
        <Skeleton
          variant='text'
          animation='wave'
          className='mx-auto rounded-lg'
          width={700}
          height={40}
        />
        <Skeleton
          variant='text'
          animation='wave'
          className='mx-auto rounded-lg'
          width={700}
          height={40}
        />
      </Grid>
    </Grid>
  );
}

export default LoadingSkeleton;
