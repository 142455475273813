import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdminHeader from './AdminHeader';
import Drawer from './Drawer';
import PendingRequestsListItem from './PendingRequestsListItem';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { getAccounts } from '../actions/accountActions';
import {
  getSearchVisas,
  getVisas,
  uploadVisa,
  visaApprove,
  visaOnreview,
  visaReject,
} from '../actions/visaActions';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/material/styles';
import { StyledTooltip } from './Drawer';

const Input = styled('input')({
  display: 'none',
});

function PendingRequestsScreen() {
  const navigate = useNavigate();
  const { isAuthenticated, current_user, loginTime } = useSelector(
    (state) => state.auth
  );
  const { accounts, accLoading } = useSelector((state) => state.accounts);
  const { visas, visaLoading } = useSelector((state) => state.visa);
  let newAccounts = accounts.filter((account) => account.createdat > loginTime);
  let newVisaRequests = visas.filter((visa) => visa.createdat > loginTime);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [id, setId] = useState('');
  const [fname, setFname] = useState('');
  const [mname, setMname] = useState('');
  const [lname, setLname] = useState('');
  const [nationality, setNationality] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [mahpassportno, setMahPassportno] = useState('');
  const [passportno, setPassportno] = useState('');
  const [doi, setDoi] = useState('');
  const [doe, setDoe] = useState('');
  const [passportFile, setPassportFile] = useState('');
  const [portraitFile, setPortraitFile] = useState('');
  const [recieptFile, setRecieptFile] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [visaFile, setVisaFile] = useState('');
  const [newVisaFile, setNewVisaFile] = useState(null);
  const [visano, setVisano] = useState('');
  const [newVisano, setNewVisano] = useState('');
  const [visadoe, setVisadoe] = useState('');
  const [newVisadoe, setNewVisadoe] = useState('');
  const [newReason, setNewReason] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/admin-login');
    }
    dispatch(getAccounts());
    dispatch(getVisas());
  }, [isAuthenticated, navigate, dispatch]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const changeEDate = (newValue) => {
    setNewVisadoe(newValue);
  };
  function viewDetail(
    id,
    fname,
    mname,
    lname,
    nationality,
    dob,
    gender,
    mahpassportno,
    passportno,
    doi,
    doe,
    portrait,
    passport,
    reciept,
    date,
    status,
    visa,
    visano,
    visadoe,
    reason
  ) {
    setId(id);
    setFname(fname);
    setMname(mname);
    setLname(lname);
    setNationality(nationality);
    setDob(dob);
    setGender(gender);
    setMahPassportno(mahpassportno);
    setPassportno(passportno);
    setDoi(doi);
    setDoe(doe);
    setPortraitFile(portrait);
    setPassportFile(passport);
    setRecieptFile(reciept);
    setDate(date);
    setStatus(status);
    setReason(reason);
    setVisaFile(visa);
    setVisano(visano);
    setVisadoe(visadoe);
    setModalOpen(true);
  }
  function onReview() {
    dispatch(visaOnreview(id));
    setModalOpen(false);
  }
  function onApprove() {
    dispatch(visaApprove(id));
    setModalOpen(false);
  }
  function onReject() {
    const rejectData = {
      id: id,
      newReason: newReason,
    };
    dispatch(visaReject(rejectData));
    setFeedbackModalOpen(false);
    setModalOpen(false);
    setNewReason('');
  }
  function onSearch(e) {
    setSearchQuery(e);
    if (searchQuery !== '') {
      dispatch(getSearchVisas(searchQuery));
    } else if (searchQuery === '') {
      dispatch(getVisas());
    }
  }
  function visaUpload() {
    const Doe = `${newVisadoe.getDate()}/${newVisadoe.getMonth()}/${newVisadoe.getFullYear()}`;
    const visaData = new FormData();
    visaData.append('id', id);
    visaData.append('visano', newVisano);
    visaData.append('visadoe', Doe);
    visaData.append('visa', newVisaFile);
    dispatch(uploadVisa(visaData));
    setNewVisano('');
    setNewVisaFile(null);
    setNewVisadoe('');
    setModalOpen(false);
  }
  return (
    <div className='admin-dashboard'>
      <div className='admin-dashboard-content'>
        {accLoading || visaLoading ? (
          <Skeleton
            variant='rectangular'
            animation='wave'
            width={500}
            height={118}
          />
        ) : (
          <AdminHeader
            newAccounts={newAccounts}
            newVisaRequests={newVisaRequests}
            current_user={current_user}
          />
        )}
        <hr />
        <div className='header-enu-item m-3'>
          <input
            type='search'
            value={searchQuery}
            onChange={(e) => onSearch(e.target.value)}
            placeholder='&#xf002;  Search'
            className='search-input'
            id=''
          />
        </div>
        <div className='accounts-list-container w-3/5'>
          <div className='accounts-list-header text-left w-full mb-4'>
            <p className='h3'>Pending Requests</p>
          </div>
          {typeof visas.msg != 'undefined' || visas.length < 1 ? (
            <p className='h4'>No Requests Yet</p>
          ) : (
            <div className='accounts-list'>
              {visas.map((visa, index) => {
                return (
                  <PendingRequestsListItem
                    id={visa.id}
                    fname={visa.fname}
                    mname={visa.mname}
                    lname={visa.lname}
                    nationality={visa.nationality}
                    dob={visa.dob}
                    gender={visa.gender}
                    mahpassportno={visa.mahrumpassportno}
                    passportno={visa.passportno}
                    doi={visa.doi}
                    doe={visa.doe}
                    portrait={visa.portrait}
                    passport={visa.passport}
                    reciept={visa.reciept}
                    filedby={visa.filedby}
                    reason={visa.reason}
                    date={visa.date}
                    status={visa.status}
                    visa={visa.visa}
                    visano={visa.visano}
                    visadoe={visa.visadoe}
                    viewDetail={viewDetail}
                    key={index}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Drawer />
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <div className='w-1/2 absolute translate-y-4 translate-x-1/2 transform bg-gray-100 p-1 rounded-lg'>
            <div className='modal-header text-left px-3'>
              <p className='h4'>Request Details</p>
            </div>
            <div className='modal-body'>
              <TabContext value={tabValue}>
                <TabList
                  value={tabValue}
                  onChange={handleChange}
                  aria-label='lab API tabs example'
                >
                  <Tab label='Details' value={1} />
                  <Tab label='Portrait' value={2} />
                  <Tab label='Passport' value={3} />
                  <Tab label='Reciept' value={4} />
                  <Tab label='Visa' value={5} />
                  <Tab label='Action' value={6} />
                </TabList>
                <TabPanel className='h-96 overflow-auto' value={1}>
                  <Grid
                    container
                    className='w-full text-left items-center'
                    spacing={0}
                  >
                    <Grid lg={12} xs={12}>
                      <p className='h4'>
                        {fname} {mname} {lname}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>{nationality}</p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date of Birth : </strong>
                        {dob}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong></strong>
                        {gender}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Passport No. : </strong>
                        {passportno}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Mahrum Passport No. : </strong>
                        {mahpassportno}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date of Issue : </strong>
                        {doi}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date of Expiry : </strong>
                        {doe}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Date Filed on : </strong>
                        {date}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Status : </strong>
                        {status}
                      </p>
                    </Grid>
                    <Grid lg={6}>
                      <p className='h5'>
                        <strong>Reason : </strong>
                        {reason}
                      </p>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel
                  className='flex justify-center items-center h-96 overflow-auto'
                  value={2}
                >
                  <img
                    src={`https://server.ethioumrah.com/client/public/images/${portraitFile}`}
                    alt='Portrait'
                    width='70%'
                  />
                </TabPanel>
                <TabPanel
                  className='flex justify-center items-center h-96 overflow-auto'
                  value={3}
                >
                  <img
                    src={`https://server.ethioumrah.com/client/public/images/${passportFile}`}
                    alt='Passport'
                    width='70%'
                  />
                </TabPanel>
                <TabPanel
                  className='flex justify-center items-center h-96 overflow-auto'
                  value={4}
                >
                  {recieptFile ? (
                    <img
                      src={`https://server.ethioumrah.com/client/public/images/${recieptFile}`}
                      alt='Reciept'
                      width='70%'
                    />
                  ) : (
                    <p className='h4'>No Reciept Uploaded</p>
                  )}
                </TabPanel>
                <TabPanel
                  className='flex justify-center items-center h-96 overflow-auto'
                  value={5}
                >
                  {visano ? (
                    <Grid
                      container
                      className='w-full text-center items-center'
                      spacing={0}
                    >
                      <Grid lg={6}>
                        <Grid lg={12}>
                          <p className='h5'>
                            <strong>Visa No. : </strong>
                            {visano}
                          </p>
                        </Grid>
                        <Grid lg={12}>
                          <p className='h5'>
                            <strong>Visa Expiry Date : </strong>
                            {visadoe}
                          </p>
                        </Grid>
                      </Grid>
                      <Grid lg={6}>
                        <StyledTooltip title='Download Visa' placement='bottom'>
                          <a href={`https://server.ethioumrah.com/client/public/uploads/${visaFile}`}>
                            <IconButton
                              color='primary'
                              aria-label='upload picture'
                              component='span'
                              type='submit'
                            >
                              <FileDownloadOutlinedIcon />
                            </IconButton>
                          </a>
                        </StyledTooltip>
                      </Grid>
                    </Grid>
                  ) : (
                    <p className='h4 text-center'>No Visa Details to Show</p>
                  )}
                </TabPanel>
                <TabPanel
                  className='flex flex-col justify-center items-center h-96 overflow-auto'
                  value={6}
                >
                  {visano == null || visano === '' ? (
                    <Grid container spacing={2}>
                      <Grid md={6}>
                        <Grid className='mt-4' md={12} xs={12}>
                          <TextField
                            id='outlined-basic'
                            label='Visa No.'
                            variant='outlined'
                            className='w-full'
                            value={newVisano}
                            onChange={(e) => setNewVisano(e.target.value)}
                          />
                        </Grid>
                        <Grid className='my-2' md={12} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                              label='Visa Expiry Date'
                              inputFormat='dd/MM/yyyy'
                              value={newVisadoe}
                              onChange={changeEDate}
                              renderInput={(params) => (
                                <TextField className='w-full' {...params} />
                              )}
                              sx={{ width: '100%' }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <div className='admin-dashboard-main-item'>
                          <div className='file-upload-container'>
                            <div className='upload-icon-container p-3 w-fit text-center items-center flex justify-center mx-auto rounded-full border border-dashed border-gray-400'>
                              {newVisaFile !== null ? (
                                <CheckRoundedIcon />
                              ) : (
                                <UploadFileIcon />
                              )}
                            </div>
                            <Box
                              sx={{
                                color: 'text.secondary',
                                fontSize: 18,
                                marginX: 'auto',
                              }}
                            >
                              Upload Visa
                            </Box>
                            <Box>
                              <div className='file-upload-button-container'>
                                <label htmlFor='visa-file'>
                                  <Input
                                    accept='.pdf'
                                    id='visa-file'
                                    name='visa'
                                    type='file'
                                    onChange={(e) =>
                                      setNewVisaFile(e.target.files[0])
                                    }
                                  />
                                  <Button
                                    sx={{ borderRadius: '2em' }}
                                    variant='contained'
                                    component='span'
                                    disableElevation
                                  >
                                    Choose File
                                  </Button>
                                </label>
                              </div>
                            </Box>
                            <Button
                              sx={{ borderRadius: '2em', marginY: '1em' }}
                              variant='contained'
                              color='success'
                              component='span'
                              disabled={
                                !newVisaFile || !newVisano || !newVisadoe
                              }
                              onClick={visaUpload}
                            >
                              Upload
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  <div className='w-full flex flex-row mt-3 justify-center'>
                    <div className='header-menu-item mx-1 my-1'>
                      <Button
                        variant='contained'
                        color='error'
                        sx={{
                          backgroundColor: '#565555',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#3c3c3c',
                            color: 'white',
                          },
                        }}
                      >
                        Blacklist
                      </Button>
                    </div>
                    <div className='header-menu-item mx-1 my-1'>
                      <Button
                        variant='contained'
                        sx={{
                          backgroundColor: '#e29191',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#c18989',
                            color: 'white',
                          },
                        }}
                        color='error'
                      >
                        Close
                      </Button>
                    </div>
                    <div className='header-menu-item mx-1 my-1'>
                      <Button
                        variant='contained'
                        color='error'
                        onClick={() => setFeedbackModalOpen(true)}
                      >
                        Reject
                      </Button>
                    </div>
                    <div className='header-menu-item mx-1 my-1'>
                      <Button
                        variant='contained'
                        color='warning'
                        onClick={onReview}
                      >
                        On Review
                      </Button>
                    </div>
                    <div className='header-menu-item mx-1 my-1'>
                      <Button variant='contained' onClick={onApprove}>
                        Approve
                      </Button>
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </div>
            <Feedback
              feedbackModalOpen={feedbackModalOpen}
              setFeedbackModalOpen={setFeedbackModalOpen}
              setModalOpen={setModalOpen}
              newReason={newReason}
              setNewReason={setNewReason}
              onReject={onReject}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
const Feedback = ({
  feedbackModalOpen,
  setFeedbackModalOpen,
  setModalOpen,
  newReason,
  setNewReason,
  onReject,
}) => {
  return (
    <React.Fragment>
      <Modal
        open={feedbackModalOpen}
        onClose={() => {
          setFeedbackModalOpen(false);
          setModalOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={feedbackModalOpen}>
          <div className='w-1/3 absolute left-32 transform translate-x-1/2 translate-y-1/2 bg-gray-100 p-3 rounded-lg'>
            <div className='modal-header text-left px-3'>
              <p className='h3'>Reason For Reject</p>
            </div>
            <div className='modal-body'>
              <TextField
                id='outlined-basic'
                label='Reason'
                variant='outlined'
                className='w-full'
                value={newReason}
                onChange={(e) => setNewReason(e.target.value)}
              />
            </div>
            <div className='modal-footer flex flex-row justify-end'>
              <div className='header-menu-item mx-1 my-1'>
                <Button
                  variant='contained'
                  disabled={!newReason}
                  onClick={onReject}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};
export default PendingRequestsScreen;
